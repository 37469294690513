import React, {useState} from "react";
import './supermercado.css'
import IMGQuadro from '../imagens/img_blocks.webp'
import IMGCheck from '../imagens/icon_check_without_bg.webp'
import IMGSupemercadoNfe from '../imagens/supermercado-img.jpg'
import IMGMoldeNotebook from '../imagens/notebook.png'
import IMGNotebook from '../imagens/cotacao-notebook.jpg'
import IMGCellMolde from '../imagens/cellM.png'
import IMGCell from '../imagens/cell-img.jpg'
import IMGCurvaABC from '../imagens/curva-abc.jpg'
import IMGCellPc from '../imagens/pc-e-cell.png'
import IMGDRE from '../imagens/dre.jpg'
import Cabecalho from "../cabecalho/cabecalho";

const Supermercado = (props) => {
    const [CheckNfe, setCheckNfe] = useState([
        'Emite NFC-e',
        'Integrado com PIX',
        'Integrado com TEF (Transferência Eletrônica de Fundos)'
    ])
    const [Contacao, setContacao] = useState([
        'O fornecedor pode enviar seus preços por celular, notebook e desktop onde tiver internet',
        'Obtendo mais rápido possível os custos das mercadorias',
        'Obtendo o resultado com maior rapidez'
    ])
    return (
        <>
        <Cabecalho/>
        <div id='div-supermercado-root'>
            <div id='supermercado-div-img'>
                
            </div>
            <div id='supermercado-div-img-titulo'>
                <p>Supermercados</p>
            </div>
            <div className="div-info-supermercado-line div-info-supermercado-line-color">
                <div className="div-info-supermercado-low-left">
                    <h1>Frente Caixa</h1>
                    {
                        CheckNfe.map((item) => 
                        <div className="list-check-supermercado">
                            <img src={IMGCheck}/>
                        <label>{item}</label>
                    </div>
                        )
                    }
                </div>
                <div className="div-info-supermercado-low-right">
                    
                        <img src={IMGSupemercadoNfe} className='imgens-lines imgens-lines-box'/>
                  
                    
                    
                        
                    
                </div>
            </div>

            <div className="div-info-supermercado-line">
                <div className="div-info-supermercado-low-left">
                    <h1>Cotação de Preços Online</h1>
                    {
                        Contacao.map((item) => 
                        <div className="list-check-supermercado">
                            <img src={IMGCheck}/>
                        <label>{item}</label>
                    </div>
                        )
                    }
                </div>
                <div className="div-info-supermercado-low-right" id='div-pc-img'>
                    
                        <img src={IMGCellPc} className='imgens-lines' id='imgens-lines-pc'/>
                  
                    
                    
                        
                    
                </div>
            </div>

            <div className="div-info-supermercado-line div-info-supermercado-line-color">
                <div className="div-info-supermercado-low-left">
                    <h1>Curva ABC</h1>
                    
                </div>
                <div className="div-info-supermercado-low-right">
                    
                        <img src={IMGCurvaABC} className='imgens-lines imgens-lines-box' id='supermercado-img-abc'/>
                  
                    
                    
                        
                    
                </div>
            </div>

            <div className="div-info-supermercado-line">
                <div className="div-info-supermercado-low-left">
                    <h1>DRE</h1>
                    
                </div>
                <div className="div-info-supermercado-low-right">
                    
                        <img src={IMGDRE} className='imgens-lines  imgens-lines-box' id='imgens-lines-dre'/>
                  
                    
                    
                        
                    
                </div>
            </div>
            
        </div>
        <div className='div-rodape'>
          <label>COPYRIGHT &copy; 2024 MegaSoftware Software House, Todos os direitos reservados. Fones: (67) 9 8111-6008 (67) 9 8162-5775</label>
       </div>
        </>
    )
}

export default Supermercado