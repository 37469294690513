import React, { useState } from "react";
import './style.css'
import Logo from '../../imagens/logo-nome.png'
import LogoIMG from '../../imagens/logo-principal.png'
import { Connection } from "../connect";
import Load from '../../imagens/load_gif.gif'

const Clientes = (props) => {
    const [Cliente, setCliente] = useState('')
    const [LoadV, setLoadV] = useState(false)
    const [LoadV2, setLoadV2] = useState(false)

    const [ListClientes, setListClientes] = useState([
       
    ])
    const [ClienteSelect, setClienteSelect] = useState(null)
    

    const CriarPedido = async () => {
        
        if (!LoadV2 & ClienteSelect != null){
            setLoadV2(true)
            let conn = new Connection()
            let resp = await conn.Intranet('criar_pedido', {
                token: localStorage.getItem('token'),
                'cliente': ClienteSelect.cod
            })
            if (resp){
                
                if (resp.status == '0'){
                    window.location.href = `/pedidos/produtos/${resp.pedido}`
                }
                else if (resp.status == '2'){
                    window.location.href = '/pedidos/login'
                }
            }
            setLoadV2(false)
        }
    }

    const Pesquisar = async() => {
        if (!LoadV){
            setLoadV(true)
            let conn = new Connection()
            let resp = await conn.Intranet('pesquisa_cliente', {
                token: localStorage.getItem('token'),
                nome_cliente: Cliente
            })
            if (resp){
                
                if (resp.status == '0'){
                    setListClientes([...resp.clientes])
                    setClienteSelect(null)
                }
                else if (resp.status == '2'){
                    window.location.href = ('/pedidos/login')
                }
            }
            setLoadV(false)
        }
    }

    return (
        <>
        <div id='div-contain-inicial'>
            <div id='div-sup-inicial'>
                <img src={LogoIMG} id='logo-inicial-img'/>
                <img src={Logo} id='logo-inicial'/>
            </div>

            <div style={{
                width: '100%',
                textAlign: 'center',
                fontFamily: 'Montserrat'
            }}>
                <p>Selecione o Cliente</p>
            </div>
            <div style={{
                padding: '20px', 
            }}>
                <input style={{
                        width: '100%',
                        fontFamily: 'Montserrat',
                        fontSize: '12pt',
                        padding: '20px',
                        border: 'none',
                        boxSizing: 'border-box',
                    }} placeholder="Digite o nome ou o código do cliente"
                    value={Cliente}
                    onChange={(event) => setCliente(event.target.value)}/>
                
            </div>
            <div id='div-button-inicial'>
                <button onClick={Pesquisar} style={{backgroundColor: 'rgb(214, 214, 87)', marginBottom: '5px'}} 
                >
                    {
                        LoadV?
                        <img src={Load} style={{width: '15px'}}/>
                        :
                        'Pesquisar Cliente'
                    }
                </button>
                </div>
            <div style={{
                backgroundColor: 'white',
                height: '350px',
                maxHeight: '350px',
                overflowY: 'scroll',
                boxSizing: 'border-box',
                padding: '10px',
            }}>
                {
                    ListClientes.length == 0?
                    <div style={{width: '100%', textAlign:'center', marginTop: '30px', color: 'grey'}}>
                        <p>Nenhum Cliente Encontrado...</p>
                    </div>
                    :
                    ListClientes.map((item, index) => (
                        <div style={{
                            padding: '20px',
                            boxSizing: 'border-box',
                        }} className="item-cliente"
                        onClick={() => {
                            setClienteSelect(item)
                        }}
                        >
                            <div style={{
                                display: 'inline-block',
                                width: '50px',
                                textAlign: 'center'
                            }}>
                                {item == ClienteSelect ? '✅' : '🟩'}
                            </div>
                            <div style={{
                                display: 'inline-block',
                                width: '80px',
                                textAlign: 'center'
                            }}>
                                {item.cod}
                            </div>
                            <div style={{
                                display: 'inline-block'
                            }}>
                                {item.nome}
                            </div>

                        </div>
                    ))
                }

            </div>

            <div id='div-button-inicial'>
            <button onClick={CriarPedido}>{
                        LoadV2?
                        <img src={Load} style={{width: '15px'}}/>
                        :
                        'Criar Pedido'
                    }</button>
        </div>
        </div>
        </>
    )
}

export default Clientes