import React, { useState, useEffect} from "react";
import Logo from '../../imagens/logo-nome.png'
import LogoIMG from '../../imagens/logo-principal.png'
import { useParams } from "react-router-dom";
import { Connection } from "../connect";




const Pedido = (props) => {
    const [DivFocus, setDivFocus] = useState('pedido')
    const [Descricao, setDescricao] = useState('')
    const [ProdutosPedido, setProdutosPedido] = useState([{
        pro_codi: '1',
        pro_desc: 'PRODUTO TESTE',
        qtd: 20,
        qtd_origin: 20,
        pro_vend: 1.0    
        },
        
    ])
    const [Finalizado, setFinalizado] = useState(false)
    const [ProdutosPesquisa, setProdutosPesquisa] = useState([
       
    ])
    const { id } = useParams()

    const [ Time, setTime ] = useState([])

    const [ProdutosPedidoOrigin, setProdutosPedidoOrigin] = useState([
    ])

    const SomarLista = (lista) => {
        let soma = 0
        lista.map((item) => {
            soma += item
        })

        return soma
    }

    const PesquisarProduto = async() => {
        let conn = new Connection()
        let resp =  await conn.Intranet('pesquisar_produtos', {
            'token': localStorage.getItem('token'),
            'produto': Descricao,
            'pedido': id
        })
        if (resp){
            if (resp.status == '0'){
                let produtos = []
                resp.produtos.map((item, index) => {
                    let _index = PesquisarID(item.pro_codi, ProdutosPedido)
                    if (_index != null){
                        item['qtd'] = ProdutosPedido[_index].qtd
                        

                    }
                    produtos.push(
                        item
                    )
                })
                setProdutosPesquisa([...produtos])
            }
        }
    }
    const Load = async() => {
        let conn = new Connection()
        let resp =  await conn.Intranet('pesquisa_pedidos', {
            'token': localStorage.getItem('token'),
            'ultimos': false,
            'id': id
        })
        if (resp){
            if (resp.status == '0'){
                console.log(resp['pedidos'][0])
                setProdutosPedido(resp['pedidos'][0]['produtos'])
                setFinalizado(resp.pedidos[0].finalizado)
            }
            
        }
        
    }

    const salvarPedido = async(item, index) => {
        let conn = new Connection()
        let resp =  await conn.Intranet('inserir_produto', {
            'token': localStorage.getItem('token'),
            'pedido': String(id),
            'pro_codi': String(item.pro_codi),
            'qtd': parseFloat(item.qtd)
        })
        if (resp){
            
            if (resp.status == '0'){
                
                PesquisarProduto()
                let produtos = ProdutosPedido
                produtos[index] = {
                    pro_codi: item.pro_codi,
                    pro_desc: item.pro_desc,
                    qtd: item.qtd,
                    pro_vend: item.pro_vend,
                    qtd_origin: item.qtd
                    }
                setProdutosPedido([...produtos])
            }
            
        }
    }

    const salvarCatalago = async(item, index) => {
        let conn = new Connection()
        let resp =  await conn.Intranet('inserir_produto', {
            'token': localStorage.getItem('token'),
            'pedido': String(id),
            'pro_codi': String(item.pro_codi),
            'qtd': parseFloat(item.qtd)
        })
        if (resp){
            
            if (resp.status == '0'){
                
                Load()
                let produtos = ProdutosPesquisa
                produtos[index] = {
                    pro_codi: item.pro_codi,
                    pro_desc: item.pro_desc,
                    qtd: item.qtd,
                    pro_vend: item.pro_vend,
                    qtd_origin: item.qtd
                    }
                setProdutosPesquisa([...produtos])
            }
            
        }
    }

    const PesquisarID = (id, lista) => {
        let index = null
        lista.map((item, _index) => {
            if (id == item.pro_codi){
                index = _index
            }
        })
        return index
    }

    const AlterarProduto = (lista, setLista, item) => {
        let index = PesquisarID(item.pro_codi, lista)
        
        if (index != null){
            
            let _lista = lista
            _lista[index] = {
                pro_codi: item.pro_codi,
                pro_desc: item.pro_desc,
                qtd: item.qtd,
                qtd_origin: item.qtd_origin,
                pro_vend: item.pro_vend    
                }
            
            setLista([..._lista])
        }
        
    }


    const Finalizar = async(item, index) => {
        let conn = new Connection()
        let resp =  await conn.Intranet('finalizar_pedido', {
            'token': localStorage.getItem('token'),
            'pedido': String(id),
        })
        if (resp){
            
            if (resp.status == '0'){
                
                Load()
                
            }
            
        }
    }

    useEffect(() => {Load()}, [])
    return(
        <>
        <div style={{
            height: '100%',
            backgroundColor: 'rgb(246, 249, 253)'
        }}>
            <div id='div-sup-inicial'>
                <img src={LogoIMG} id='logo-inicial-img'/>
                <img src={Logo} id='logo-inicial'/>
            </div>
            <div style={{
                boxSizing: 'border-box',
                padding: '5px'
            }}>

            
                <div style={{
                    marginTop: '10px'
                }}>
                    <div onClick={() => setDivFocus('pedido')} style={DivFocus == 'pedido'? styles.div_flags_focus : styles.div_flags}>
                        <p>Pedido</p>
                    </div>
                    <div onClick={() => setDivFocus('catalogo')} style={DivFocus == 'catalogo'? styles.div_flags_focus : styles.div_flags}>
                        <p>Catálogo</p>
                    </div>
                </div>
                {
                    DivFocus == 'pedido' ?
                    <div style={{
                        height: '500px',
                        backgroundColor: 'white',
                        border: '1px solid grey',
                        borderTop: 'none',
                        boxSizing: 'border-box',
                        borderBottomLeftRadius: '5px',
                        borderBottomRightRadius: '5px',
                        padding: '5px'
                    }}>
                        <div style={{
                            width: '100%',
                            height: '400px',
                            borderBottom: '1px solid grey',
                            maxHeight: '400px',
                            overflowY: 'scroll'
                        }}>
                            {
                                ProdutosPedido.map((item, index) => {
                                    
                                    return (
                                        <div style={{
                                            borderBottom: '1px dotted black',
                                            marginTop: '10px'
                                        }}>
                                            <div style={{
                                                width: '70%',
                                                boxSizing: 'border-box',
                                                display: 'inline-block',
                                                verticalAlign: 'top'
                                            }}>
                                                <p
                                                style={{
                                                    fontFamily: 'Montserrat',
                                                    fontSize: '13pt',
                                                    fontWeight: '600',
                                                    margin: '3px'
                                                }}
                                                >{item.pro_desc}</p>
                                                <p
                                                style={{
                                                    fontFamily: 'Montserrat',
                                                    fontSize: '10pt',
                                                    fontWeight: '500',
                                                    margin: '3px',
                                                    marginTop: '10px'

                                                
                                                }}
                                                >TOTAL: {(item.pro_vend * item.qtd).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} | UN: {item.pro_vend.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                            </div>
                                            <div style={{
                                                width: '30%',
                                                boxSizing: 'border-box',
                                                display: 'inline-block',
                                                verticalAlign: 'top'
                                            }}>
                                                <div style={{
                                                    border: '1px solid grey',
                                                    boxSizing: 'border-box',
                                                    padding: '5px',
                                                    borderRadius: '50px',
                                                    textAlign: 'center'
                                                }}>
                                                    <p
                                                    style={{
                                                        fontSize: '15pt',
                                                        margin: '0px',
                                                        marginLeft: '5px',
                                                        fontWeight: '500',
                                                        display: 'inline-block',
                                                        width: '20%',
                                                        textAlign: 'left'
                                                    }}
                                                    onClick={() => {
                                                        let produtos = ProdutosPedido
                                                        produtos[index] = {
                                                            pro_codi: item.pro_codi,
                                                        pro_desc: item.pro_desc,
                                                        qtd: (item.qtd - 1) > 0 ? item.qtd - 1 : 0,
                                                        pro_vend: item.pro_vend,
                                                        qtd_origin: item.qtd_origin    
                                                            }
                                                        setProdutosPedido([...produtos])
                                                        AlterarProduto(ProdutosPesquisa, setProdutosPesquisa, produtos[index])
                                                    }}
                                                    >-</p>
                                                    <input style={{
                                                        display: 'inline-block',
                                                        width: '40%',
                                                        boxSizing: 'border-box',
                                                        border: 'none',
                                                        outline: 'none',
                                                        textAlign: 'center',
                                                    }}
                                                    type='number'
                                                    value = {item.qtd}
                                                    onChange={(event) => {
                                                        
                                                      let produtos = ProdutosPedido
                                                      produtos[index] = {
                                                        pro_codi: item.pro_codi,
                                                        pro_desc: item.pro_desc,
                                                        qtd: event.target.value > 0 ? parseFloat(event.target.value) : 0,
                                                        pro_vend: item.pro_vend,
                                                        qtd_origin: item.qtd_origin    
                                                        }
                                                      setProdutosPedido([...produtos])
                                                        
                                                    }}
                                                    
                                                    />
                                                    <p
                                                    style={{
                                                        fontSize: '15pt',
                                                        margin: '0px',
                                                        marginLeft: '5px',
                                                        fontWeight: '500',
                                                        display: 'inline-block',
                                                        width: '20%',
                                                        textAlign: 'right'
                                                    }}
                                                    onClick={() => {
                                                        let produtos = ProdutosPedido
                                                        produtos[index] = {
                                                            pro_codi: item.pro_codi,
                                                        pro_desc: item.pro_desc,
                                                        qtd: item.qtd + 1,
                                                        pro_vend: item.pro_vend,
                                                        qtd_origin: item.qtd_origin    
                                                            }
                                                        setProdutosPedido([...produtos])
                                                        
                                                    }}
                                                    >+</p>
                                                
                                                </div>
                                                <div style={{
                                                    boxSizing: 'border-box',
                                                    width: '100%',
                                                    textAlign: 'center'
                                                }}>
                                                    <button style={item.qtd == item.qtd_origin? {
                                                        marginTop: '10px',
                                                        marginBottom: '10px',
                                                        textAlign: 'center',
                                                        boxSizing: 'border-box',
                                                        padding: '5px',
                                                        width: '100%',
                                                        borderRadius: '10px',
                                                        border: 'none',
                                                        backgroundColor:  'grey',
                                                        color: 'white'
                                                    }:
                                                    {
                                                        marginTop: '10px',
                                                        marginBottom: '10px',
                                                        textAlign: 'center',
                                                        boxSizing: 'border-box',
                                                        padding: '5px',
                                                        width: '100%',
                                                        borderRadius: '10px',
                                                        border: 'none',
                                                        backgroundColor:  'rgb(59, 165, 59)',
                                                        color: 'white',
                                                        cursor: 'pointer'
                                                    }} disabled={item.qtd == item.qtd_origin ? true : false}
                                                    onClick={() =>salvarPedido(item, index)}>
                                                        Salvar
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div style={{
                            width: '100%',
                            textAlign: 'center'
                        }}>
                            <div style={{
                                display: 'inline-block'
                            }}>
                                <p>Total Geral</p>
                                <p
                                style={{
                                    fontSize: '17pt',
                                    margin: '0px'
                                }}>
                                    {
                                        SomarLista(ProdutosPedido.map((item) => {
                                            return item.pro_vend * item.qtd
                                        })).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                                    }
                                </p>
                            </div>
                            <div style={{
                                display: 'inline-block',
                                marginLeft: '50px'
                            }}>
                                <p>Total Salvo</p>
                                <p
                                style={{
                                    fontSize: '17pt',
                                    margin: '0px'
                                }}>
                                    {
                                        SomarLista(ProdutosPedido.map((item) => {
                                            return item.pro_vend * item.qtd_origin
                                        })).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                    :
                    <div style={{
                        height: '500px',
                        backgroundColor: 'white',
                        border: '1px solid grey',
                        borderTop: 'none',
                        boxSizing: 'border-box',
                        borderBottomLeftRadius: '5px',
                        borderBottomRightRadius: '5px',
                        padding: '5px'
                    }}>
                    <input style={{
                        width: '100%',
                        fontFamily: 'Montserrat',
                        fontSize: '12pt',
                        padding: '20px',
                        border: '1px solid rgb(59, 165, 59)',
                        boxSizing: 'border-box',
                        borderRadius: '5px'
                    }} placeholder="Digite a descrição ou código"
                    
                    onKeyUp={() => {
                        try{
                            clearTimeout(Time[0])
                        }
                        catch {

                        }
                        
                        let a = setTimeout(() => {PesquisarProduto()}, 1000)
                     
                        setTime([...[a]])
                    }}
                    value={Descricao}
                    onChange={(event) => setDescricao(event.target.value)}
                    />
                    <div style={{
                        height: '420px',
                        maxHeight: '420px',
                        overflowY: 'scroll'
                    }}>
                    {
                                ProdutosPesquisa.map((item, index) => {
                                    
                                    return (
                                        <div style={{
                                            borderBottom: '1px dotted black',
                                            marginTop: '10px'
                                        }}>
                                            <div style={{
                                                width: '70%',
                                                boxSizing: 'border-box',
                                                display: 'inline-block',
                                                verticalAlign: 'top'
                                            }}>
                                                <p
                                                style={{
                                                    fontFamily: 'Montserrat',
                                                    fontSize: '13pt',
                                                    fontWeight: '600',
                                                    margin: '3px'
                                                }}
                                                >{item.pro_desc}</p>
                                                <p
                                                style={{
                                                    fontFamily: 'Montserrat',
                                                    fontSize: '10pt',
                                                    fontWeight: '500',
                                                    margin: '3px',
                                                    marginTop: '10px'

                                                
                                                }}
                                                >TOTAL: {(item.pro_vend * item.qtd).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} | UN: {item.pro_vend.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                            </div>
                                            <div style={{
                                                width: '30%',
                                                boxSizing: 'border-box',
                                                display: 'inline-block',
                                                verticalAlign: 'top'
                                            }}>
                                                <div style={{
                                                    border: '1px solid grey',
                                                    boxSizing: 'border-box',
                                                    padding: '5px',
                                                    borderRadius: '50px',
                                                    textAlign: 'center'
                                                }}>
                                                    <p
                                                    style={{
                                                        fontSize: '15pt',
                                                        margin: '0px',
                                                        marginLeft: '5px',
                                                        fontWeight: '500',
                                                        display: 'inline-block',
                                                        width: '20%',
                                                        textAlign: 'left'
                                                    }}
                                                    onClick={() => {
                                                        let produtos = ProdutosPesquisa
                                                        produtos[index] = {
                                                            pro_codi: item.pro_codi,
                                                        pro_desc: item.pro_desc,
                                                        qtd: (item.qtd - 1) > 0 ? item.qtd - 1 : 0,
                                                        pro_vend: item.pro_vend,
                                                        qtd_origin: item.qtd_origin    
                                                            }
                                                        setProdutosPesquisa([...produtos])
                                                        
                                                    }}
                                                    >-</p>
                                                    <input style={{
                                                        display: 'inline-block',
                                                        width: '40%',
                                                        boxSizing: 'border-box',
                                                        border: 'none',
                                                        outline: 'none',
                                                        textAlign: 'center',
                                                    }}
                                                    type='number'
                                                    value = {item.qtd}
                                                    onChange={(event) => {
                                                        
                                                      let produtos = ProdutosPesquisa
                                                      produtos[index] = {
                                                        pro_codi: item.pro_codi,
                                                        pro_desc: item.pro_desc,
                                                        qtd: event.target.value > 0 ? parseFloat(event.target.value): 0,
                                                        pro_vend: item.pro_vend,
                                                        qtd_origin: item.qtd_origin    
                                                        }
                                                      setProdutosPesquisa([...produtos])
                                                        
                                                    }}
                                                    
                                                    />
                                                    <p
                                                    style={{
                                                        fontSize: '15pt',
                                                        margin: '0px',
                                                        marginLeft: '5px',
                                                        fontWeight: '500',
                                                        display: 'inline-block',
                                                        width: '20%',
                                                        textAlign: 'right'
                                                    }}
                                                    onClick={() => {
                                                        let produtos = ProdutosPesquisa
                                                        produtos[index] = {
                                                            pro_codi: item.pro_codi,
                                                        pro_desc: item.pro_desc,
                                                        qtd: item.qtd + 1,
                                                        pro_vend: item.pro_vend,
                                                        qtd_origin: item.qtd_origin    
                                                            }
                                                        setProdutosPesquisa([...produtos])
                                                        
                                                    }}
                                                    >+</p>
                                                
                                                </div>
                                                <div style={{
                                                    boxSizing: 'border-box',
                                                    width: '100%',
                                                    textAlign: 'center'
                                                }}>
                                                    <button style={item.qtd == item.qtd_origin? {
                                                        marginTop: '10px',
                                                        marginBottom: '10px',
                                                        textAlign: 'center',
                                                        boxSizing: 'border-box',
                                                        padding: '5px',
                                                        width: '100%',
                                                        borderRadius: '10px',
                                                        border: 'none',
                                                        backgroundColor:  'grey',
                                                        color: 'white'
                                                    }:
                                                    {
                                                        marginTop: '10px',
                                                        marginBottom: '10px',
                                                        textAlign: 'center',
                                                        boxSizing: 'border-box',
                                                        padding: '5px',
                                                        width: '100%',
                                                        borderRadius: '10px',
                                                        border: 'none',
                                                        backgroundColor:  'rgb(59, 165, 59)',
                                                        color: 'white',
                                                        cursor: 'pointer'
                                                    }} disabled={item.qtd == item.qtd_origin ? true : false}
                                                    onClick={() => salvarCatalago(item, index)}>
                                                        Salvar
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    )
                                })
                            }
                    </div>
                    </div>
                }
            </div>
            <div style={{
                width: '100%',
                textAlign: 'center',
                boxSizing: 'border-box',
                padding: '10px',
                marginTop: '10px'
            }}>
                <button style={{
                    backgroundColor: Finalizado ? 'grey': 'rgb(59, 165, 59)',
                    width: '100%',
                    color: 'white',
                    padding: '10px',
                    border: 'none',
                    borderRadius: '2px',
                    fontFamily: 'Montserrat',
                    fontSize: '13pt'
                }}
                disabled={Finalizado}
                onClick={Finalizar}
                >
                    {
                        Finalizado ?
                        'Finalizado'
                        :
                        'Finalizar'
                    }
                    
                </button>
            </div>
            
        </div>
        
        
        </>
    )
}

const styles = {
    div_flags: {
        display: 'inline-block',
        width: '50%',
        textAlign: 'center',
        boxSizing: 'border-box',
        border: '1px solid grey',
        fontFamily: 'Montserrat',
        borderBottom: '1px solid grey',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px'
    },
    div_flags_focus: {
        display: 'inline-block',
        width: '50%',
        textAlign: 'center',
        boxSizing: 'border-box',
        border: '1px solid grey',
        backgroundColor: 'white',
        borderBottom: '1px solid white',
        fontFamily: 'Montserrat',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px'
    }
}

export default Pedido