import logo from './logo.svg';
import './App.css';
import PCImagem from './imagens/pc-imagem.jpg'
import Cabecalho from './cabecalho/cabecalho';

import Titulo from './titulo/titulo';
import Sobre from './sobre/sobre';
import Supermercado from './supermercados/supermercado';
import Materiais from './materiais/materiais';
import AutoPecas from './autopecas/autopecas';
import AlgunsCliente from './alguns/algunsclientes';

import Login from './pedidos/login';
import Inicial from './pedidos/inicial';
import Clientes from './pedidos/clientes/clientes';
import Pedido from './pedidos/pedido/pedido';

import { Routes, Route } from 'react-router-dom';
import { useState } from 'react';



function App() {
  const [CabecalhoVisi, setCabecalhoVisi] = useState(true)
  return (
    <>
      <div>
       <Routes>
        
          <Route exact  path="/" element={<Titulo />} />
          <Route exact path="/sobre" element={<Sobre/>} />
          <Route exact path='/supermercado' element={<Supermercado/>} />
          <Route exact path='/materiaisdeconstrucao' element={<Materiais/>} />
          <Route exact path='/autopecas' element={<AutoPecas/>} />
          <Route exact path='/algunsclientes' element={<AlgunsCliente/>} />

          <Route exact path='/pedidos/login' element={<Login/>} />
          <Route exact path='/pedidos' element={<Inicial/>} />
          <Route exact path='/pedidos/cliente' element={<Clientes/>} />
          <Route exact path='/pedidos/produtos/:id' element={<Pedido/>} />
       </Routes>
       </div>  
    </>
  )
}





export default App;
