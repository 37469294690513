import React, {useState} from "react";
import './titulo.css'
import WhatsIcon from '../imagens/whats.png'
import Cabecalho from "../cabecalho/cabecalho";

const Titulo = (props) => {
    function opennewwin(){
        var awindow = window.open("http://api.whatsapp.com/send?1=pt_BR&phone=556781116008&text=Ol%c3%a1%2c+eu+quero+contratar+um+plano.",  '_blank');
    
    }
    return (
        <>
        <Cabecalho/>
        <div id='corpo-div'>
            <div id='div-corpo-superior'>
                <p id='p-nome-empresa'>MegaSoftware</p>
                <label>O SEU <span>ERP</span> COMPLETO, QUE ATENDE PEQUENAS, MÉDIAS E GRANDES EMPRESAS</label>
                <p id='p-inferior-div-corpo-superior'>Garanta uma gestão empresarial de alto nível e lucre muito mais.</p>
                
                <div id='div-titulo-fone' onClick={() => {opennewwin()}} target="_blank">
                    <label>Ligue Agora: (67) 9 8111-6008 ou (67) 9 8162-5775<img id='icon-whats-img'src={WhatsIcon}/></label>
                </div>
            </div>
            <div id ='div-corpo-descricional'>
                <p id ='p-titulo-superior-descricional'>Sistemas de Gestão Empresarial</p>
                <hr id='linha-divisao'/>
                <div id ='div-p-titulo-superior-descricional2'>
                    <p id ='p-titulo-superior-descricional2'>Veja os benefícios de um ERP dinâmico e escalável para o seu negócio</p>
                </div>
                
            </div>
            
        </div>
        
        <div className='div-rodape'>
          <label>COPYRIGHT &copy; 2024 MegaSoftware Software House, Todos os direitos reservados. Fones: (67) 9 8111-6008 (67) 9 8162-5775</label>
       </div>
        </>
    )
}

export default Titulo