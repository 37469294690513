import React, { useEffect, useState } from "react";
import './style.css'
import Logo from '../../imagens/logo-nome.png'
import LogoIMG from '../../imagens/logo-principal.png'
import { Connection } from "../connect";
import Load from '../../imagens/load_gif.gif'


const Inicial = (props) => {
    const [Pedidos, setPedidos] = useState([])

    const [Cliente, setCliente] = useState('')
    const [Finalizado, setFinalizado] = useState('1')
    const [LoadV, setLoadV] = useState(false)

    

    const Pesquisar = async () => {
        if (!LoadV){
            setLoadV(true)
            const conn = new Connection()
            let dados_env = {
                'token': localStorage.getItem('token'),
                'cliente_nome': Cliente,
                'finalizado': Finalizado == '1' ? null : Finalizado == '2'? true : false,
                'ultimos': false
            }
            let resp = await conn.Intranet('pesquisa_pedidos', dados_env)
            if (resp){
                if (resp.status == '0'){
                    setPedidos([...resp.pedidos])
                }
            }
            setLoadV(false)
        }
    }
    useEffect(() => Pesquisar, [])
    return (
        <div id='div-contain-inicial'>
        <div id='div-sup-inicial'>
            <img src={LogoIMG} id='logo-inicial-img'/>
            <img src={Logo} id='logo-inicial'/>
        </div>
        
        <div style={{width: '100%'}}>
            <div style={{
                padding: '20px',
                boxSizing: 'border-box',
                paddingBottom: '0px',
            }}>
                <p style={{display: 'inline-block', textAlign: "left", width: '50%', 
                    fontSize: '13pt',
                    fontFamily: 'Montserrat',
                    fontWeight: '700',
                   
                    }}>Pedidos</p>
                <p style={{display: 'inline-block', textAlign: "right", width: '50%',
                    fontSize: '13pt',
                    fontFamily: 'Montserrat',
                    color: 'Darkgreen',
                  
                    }}>Filtrar</p>
            </div>
            <div style={{padding: '20px', paddingRight: '30px'}}>
                <input style={{
                    width: '100%',
                    fontFamily: 'Montserrat',
                    fontSize: '12pt',
                    padding: '20px',
                    border: 'none',
                    boxSizing: 'border-box',
                }} placeholder="Digite o nome do cliente"
                value={Cliente}
                onChange={(event) => setCliente(event.target.value)}/>
                <select style={{
                    width: '100%',
                    fontFamily: 'Montserrat',
                    fontSize: '12pt',
                    padding: '20px',
                    border: 'none',
                    boxSizing: 'border-box',
                    backgroundColor: "white",
                    marginTop: '10px'
                }}
                value={Finalizado}
                onChange={(event) => setFinalizado(event.target.value)}
                >
                    <option value='1'>Todos</option>
                    <option value='2'>Finalizados</option>
                    <option value='3'>Em Aberto</option>
                    
                </select>
            </div>
            <div id='div-button-inicial'>
            <button style={{backgroundColor: 'rgb(214, 214, 87)', marginBottom: '5px'}} 
                onClick={Pesquisar}>
                    {
                        LoadV?
                        <img src={Load} style={{width: '15px'}}/>
                        :
                        'Pesquisar Pedidos'
                    }
                </button>
        </div>
            <div id='div-pedidos-list'>
                {
                    Pedidos.length == 0 ?
                    <div style={{width: '100%', textAlign:'center', marginTop: '30px', color: 'grey'}}>
                        <p>Nenhum Pedido Encontrado...</p>
                    </div>
                    :
                    Pedidos.map((item) => {
                        return (
                            <div style={{
                                width: '100%',
                                boxSizing: 'border-box',
                                padding: '10px',
                                
                            }} 
                            onClick={() => {
                                window.location.href = `/pedidos/produtos/${item.id}`
                            }}
                            className="pedido-item">
                                <div style={{
                                    width: '70%',
                                    display: 'inline-block',
                                    verticalAlign: 'middle'
                                }}>
                                    <h1 style={{
                                        fontSize: '12pt',
                                        fontFamily: 'Montserrat',
                                    }}>{item.cliente}</h1>
                                    <p style={{
                                        fontSize: '11pt',
                                        color: 'gray',
                                        fontFamily: 'Montserrat',
                                    }}>{item.iniciado} {'|'} {item.total}</p>
                                </div>
                                <div style={{
                                    width: '30%',
                                    display: 'inline-block',
                                    verticalAlign: 'middle',
                                    textAlign: 'right'
                                }} >
                                    {
                                        item.finalizado?
                                        <div style={{
                                            padding: '5px',
                                            backgroundColor: 'rgb(59, 165, 59)',
                                            verticalAlign: 'middle',
                                            color:'white',
                                            fontSize: '8pt',
                                            width: '60px',
                                            textAlign: "center",
                                            display: 'inline-block'
                                        }}>
                                            FINALIZADO
                                        </div>
                                        :
                                        <div style={{
                                            padding: '5px',
                                            backgroundColor: 'red',
                                            verticalAlign: 'middle',
                                            color:'white',
                                            fontSize: '8pt',
                                            width: '60px',
                                            textAlign: "center",
                                            display: 'inline-block'
                                        }}>
                                            ABERTO
                                        </div>
                                    }

                                    <p
                                    style={{
                                        display: 'inline-block',
                                        marginLeft: '20px',
                                        fontFamily: 'Roboto',
                                        fontSize: '15pt'
                                    }}
                                    >{'>'}</p>
                                    
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
        <div id='div-button-inicial'>
            <button onClick={() => window.location.href = '/pedidos/cliente'}>Novo Pedido</button>
        </div>
        </div>
    )
}

export default Inicial