import React, { useState } from "react";
import './style.css'
import LoginImagem from '../../imagens/login_pedido.jpg'
import Logo from '../../imagens/logo.png'
import Load from '../../imagens/load_gif.gif'
import InputMask from 'react-input-mask';
import { Connection } from "../connect";


const Login = (props) => {
    const [FocusCnpj, setFocusCnpj] = useState(false)
    const [FocusSenha, setFocusSenha] = useState(false)

    const [LoadV, setLoadV] = useState(false)

    const [CNPJ, setCNPJ] = useState(localStorage.getItem('cnpj') ? localStorage.getItem('cnpj') : '')
    const [Senha, setSenha] = useState('')

    const [SenhaIncorreta, setSenhaIncorreta] = useState(false)

    

    const Entrar = async () => {
        
        if (!LoadV){
            setLoadV(true)
            const conn = new Connection()
           
            let resp = await conn.Intranet('login_empresa', {cnpj: CNPJ, senha: Senha})

            if (resp){
                if (resp.status == '0'){
                    
                    localStorage.setItem('token', resp.token)
                    localStorage.setItem('cnpj', CNPJ)
                
                    window.location.href = '/pedidos'
                }
                else if(resp.status == '2'){
                    setSenhaIncorreta(true)
                }
            }
            setLoadV(false)
        }
        
    }

    return (
        <div id='div-login'>
            <div id='contain-login'>
                <div style={{ backgroundImage: `linear-gradient(90deg,rgba(252, 239, 0, 0.85) 10%, rgba(0, 78, 10, 0.85) 100%), url(${LoginImagem})` }} id='div-img-login'>
                    <img src={Logo} id='logo-mega'/>
                    <p id='p-titulo'>Pedidos Online</p>
                </div>
                <div id='div-sup-login'  style={{ backgroundColor: 'white'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" id="bg-login" viewBox="0 0 1251 84.406">
                        <path id="Retângulo_1" data-name="Retângulo 1" class="cls-1" d="M58,384H1309v55s-321.707,66.18-622.088,0C345.939,363.839,58,439,58,439V384Z"
                        transform="translate(-58 -384)"/>

                    </svg>
                    <div id='div-inputs-login'>
                        <div >
                            <p className="p-input">CNPJ</p>
                            <InputMask value={CNPJ} type='user'
                            onChange={(event) => setCNPJ(event.target.value)}
                            onFocus={() => setFocusCnpj(true)} onBlur={() => setFocusCnpj(false)} mask="99.999.999/9999-99" maskChar=" " className="input-mobile" placeholder="Digite seu CNPJ"/>
                        </div>
                        <div style={{marginTop: '60px'}}>
                            <p 
                            
                            className="p-input">Senha</p>
                            <input type='password' 
                            value={Senha} 
                            onChange={(event) => setSenha(event.target.value)}
                            onFocus={() => setFocusSenha(true)} onBlur={() => setFocusSenha(false)} className="input-mobile" placeholder="Digite sua senha" />
                        </div>
                        <p style={{width: '100%', textAlign: 'center', fontSize: '10pt', color: 'red'}}
                        >{SenhaIncorreta?'Usuário ou senha incorreto.' : ' '}</p>
                        <div style={{width: '100%', marginTop: '50px'}}>
                            <button id='button-login' onClick={Entrar}>{
                                LoadV?
                                <img src={Load} style={{width: '15px'}}/>
                                : 'ENTRAR'
                            }</button>
                        </div>
                    </div>

                </div>
                
            </div>
        </div>
    )
}

export default Login